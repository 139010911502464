import vrt from '../assets/img/game_BA/2/d2.jpg'
import kuhinja from '../assets/img/game_BA/2/k2.jpg'
import outItem1 from '../assets/img/game_BA/2/vrt/1.png'
import outItem2 from '../assets/img/game_BA/2/vrt/2.png'
import outItem3 from '../assets/img/game_BA/2/vrt/3.png'
import outItem4 from '../assets/img/game_BA/2/vrt/4.png'
import outItem5 from '../assets/img/game_BA/2/vrt/5.png'
import p1 from '../assets/img/game/2/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game_BA/2/kuhinja/2pro.webp'
import k2 from '../assets/img/game_BA/2/kuhinja/4pro.png'
import k3 from '../assets/img/game_BA/2/kuhinja/5997381311908-drOetker-prasak-za-pecivo-1.png'
import k4 from '../assets/img/game_BA/2/kuhinja/8pro.png'
import k5 from '../assets/img/game_BA/2/kuhinja/van.jpg'
import k6 from '../assets/img/game_BA/2/kuhinja/rec2.png'
import k7 from '../assets/img/game_BA/2/kuhinja/recept8.png'
import k8 from '../assets/img/game_BA/2/kuhinja/recept2.png'
import k9 from '../assets/img/game_BA/2/kuhinja/rec8.png'
import k10 from '../assets/img/game_BA/2/kuhinja/Torta u obliku zeca.png'

const day_2 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "216.8deg", pitch: "-22.7deg" },
                size: { width: 20, height: 24 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-42.5deg", pitch: "-10.3deg" },
                size: { width: 16, height: 21 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-68.2deg", pitch: "-14.2deg" },
                size: { width: 17, height: 22 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "146.3deg", pitch: "-41deg" },
                size: { width: 17, height: 22 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "38deg", pitch: "-9.2deg" },
                size: { width: 19, height: 23 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-36deg", pitch: "10deg" },
                size: { width: 37, height: 80 },
                product_title: 'Aroma ruma u bočici',
                product_description: 'Dr. Oetker aroma ruma praktična je za aromatiziranje najfinijih deserata, napitaka, tijesta i nadjeva.',
                product_image: k1,
                product_url: 'https://www.oetker.ba/ba-bs/nasi-proizvodi/dodaci-za-kolace/arome/aroma-ruma-u-bocici'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-87.2deg", pitch: "-24.5deg" },
                size: { width: 30, height: 80 },
                product_title: 'Aroma vanilije u bočici',
                product_description: 'S Dr. Oetker aromom vanilije vaši će kolači i deserti jednostavno postati zavodljiviji.',
                product_image: k2,
                product_url: 'https://www.oetker.ba/ba-bs/nasi-proizvodi/dodaci-za-kolace/arome/aroma-vanilije-u-bocici'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-52.5deg", pitch: "-9.7deg" },
                size: { width: 58, height: 105 },
                product_title: 'Original Backin prašak za pecivo',
                product_description: 'Bakin recept i Dr. Oetker Prašak za pecivo savršena su kombinacija za najfinija peciva i kolače.',
                product_image: k3,
                product_url: 'https://www.oetker.ba/ba-bs/nasi-proizvodi/dodaci-za-kolace/original-backin-prasak-za-pecivo'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-71deg", pitch: "-22deg" },
                size: { width: 55, height: 80 },
                product_title: 'Salep',
                product_description: 'Aromatizirani instant napitak s mlijekom u prahu. Idealan napitak za hladne zimske dane koji će uz Salep postati topliji.',
                product_image: k4,
                product_url: 'https://www.oetker.ba/ba-bs/nasi-proizvodi/salep/salep'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "16deg", pitch: "-13.8deg" },
                size: { width: 60, height: 70 },
                product_title: 'Creative puding vanilija kardamom',
                product_description: 'Pripremite se za neočekivani spoj dva uzbudljiva okusa – vanilija i kardamom! Creative pudinzi osmišljeni su jedinstvenom kombinacijom dvaju suprotnih okusa koji će vas očarati svježinom uz dobro poznatu teksturu pudinga.',
                product_image: k5,
                product_url: 'https://www.oetker.ba/ba-bs/nasi-proizvodi/creative-pudding/creative-pudding-vanilija-kadamom'
            },
            {
                id: 6,
                trigger_image: ' ',
                position: { yaw: "-46.8deg", pitch: "-32deg" },
                size: { width: 156, height: 154 },
                product_title: 'Đul baklava',
                product_description: 'Svaki zalogaj pruža slatku eksploziju orašaste arome i arome Creative pudinga od vanilije i kardama. Ovaj recept donosi autentičan doživljaj baklavine slasti u udobnosti vašeg doma, čineći ga idealnim izborom za posebne prigode ili jednostavno za uživanje u tradicionalnoj poslastici.',
                product_image: k6,
                product_url: 'https://bih.oetker-recepti.com/dul-baklava/?fbclid=IwAR2tBmjZfIZ98F4vhYiNN2snMQ_7L77cdWB6FzjHAxXbJCU6hNjHkTtP7RE'
            },
            {
                id: 7,
                trigger_image: ' ',
                position: { yaw: "257.2deg", pitch: "-25.7deg" },
                size: { width: 150, height: 180 },
                product_title: 'Torta u obliku zeca',
                product_description: 'Torta u obliku zeca je slatka poslastica koja će očarati sve svojim šarmantnim izgledom i neodoljivim ukusom. Ova zabavna torta priprema se u obliku simpatičnog zeca, a idealna je za posebne prigode poput dječjih rođendana ili uskršnjih proslava, te će sigurno donijeti osmijeh na lica svih gostiju svojom simpatičnom pojavom i fantastičnim okusom.',
                product_image: k7,
                product_url: 'https://www.oetker.ba/ba-bs/rezepte/r/torta-u-obliku-zeca'
            },
            {
                id: 8,
                trigger_image: ' ',
                position: { yaw: "-126.5deg", pitch: "-45.4deg" },
                size: { width: 150, height: 150 },
                product_title: 'Američki kolačići',
                product_description: 'Ovi slatki američki keksi od ukusnog miješanog tijesta, ukrašeni poput pilića, pravi su mamac za poglede na svečanom uskrsnom stolu.',
                product_image: k8,
                product_url: 'https://www.oetker.ba/ba-bs/rezepte/r/americki-kolacici'
            },
            {
                id: 9,
                trigger_image: ' ',
                position: { yaw: "-193.5deg", pitch: "-50.6deg" },
                size: { width: 240, height: 220 },
                product_title: 'Kolač s mrkvom',
                product_description: 'Kolač s mrkvom kao savršena slastica za blagdane. Lagani biskvit, glazura od šećera u prahu i mrkve od marcipana čine ovaj kolač iznimno ukusnim.',
                product_image: k9,
                product_url: 'https://www.oetker.ba/ba-bs/rezepte/r/kolac-s-mrkvom'
            },
            {
                id: 10,
                trigger_image: ' ',
                position: { yaw: "-15.8deg", pitch: "-41.5deg" },
                size: { width: 146, height: 190 },
                product_title: 'Torta u obliku zeca',
                product_description: 'Efektna, razigrana i ukusna torta - idealna za obiteljsko okupljanje ili kao poklon za Uskrs',
                product_image: k10,
                product_url: 'https://www.oetker.ba/ba-bs/rezepte/r/torta-od-vanilije-u-obliku-zeca'
            },
        ]
    },
]

export default day_2