import React from 'react'
import mainContentPrizes from '../assets/img/BIH-glavna-nagrada.png'

const MainContent = () => {

  return (
    <div>
      <article className="mainContent pb-6 bg-white">
        <div className="px-2 lg:px-9">
          <h1 className="font-bold text-2xl sm:text-3xl lg:text-4xl mb-8 lg:mb-10">I ovog proljeća Dr. Oetker nagrađuje!</h1>
          <h2 className="font-bold text-xl sm:text-2xl lg:text-3xl max-w-[60ch] mx-auto mb-8 lg:mb-10">Uključite se u VELIKI NAGRADNI NATJEČAJ i osvojite set kalupa ili Bosch aparat za kavu.</h2>
          <p>Svi željno iščekujemo blagdane i blagdanski stol pun slastica – tradicionalnih i onih modernijih. Kako bi priprema kolača bila još veselija, ovog proljeća pripremili smo za vas veliki nagradni natječaj u kojem možete osvojiti vrijedne nagrade. </p>
          <p>Za svaki dan, u periodu trajanja nagradnog natječaja od 24. ožujka. - 9. travnja 2024. godine, smo pripremili vrijedne nagrade - 15x setova kalupa i plišanu ovcu.</p>
          <p>Sve pristigle prijave ulaze u izvlačenje za glavnu nagradu – Bosch aparat za kavu!</p>
          <p>Sretne dobitnike izvlačimo na kraju nagradnog natječaja. </p>
          <br />
          <p><strong>Kako sudjelovati?</strong></p>
          <p>Odgovorom na kreativno pitanje i potragom za pisanicama i proizvodima ostvarujete pravo sudjelovanja u nagradnom natječaju na način da popunite polja obrasca za prijavu koji se nalazi u nastavku.</p>
          <p>Slijedite par jednostavnih koraka jer nagrada može biti baš vaša!</p>
          <br />
          <p>Sretno!</p>
         {/*} <br />
          <a href="https://www.oetker.ba/ba-bs/svijet-dekora/dobitnici-nagradnog-natjecaja" target="_blank" className="basic-button">Dobitnici nagradnog natječaja</a>*/}
        </div>
      </article>
    </div>
  )

}

export default MainContent